<template>
   
        <v-data-table
            :headers="headers"
            :items="data"
            
            class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Organization Data</v-toolbar-title>
                              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
               {{ item['User_uu.FirstName'] + " " +item['User_uu.LastName'] }}
            </template>
            <template v-slot:item.caseload_count="{ item }">
                <v-text-field
                @blur="updateCount(item)"
                    v-model="item.caseload_count"
                    type="number"
                    dense
                    class="mt-5"
                    solo
                ></v-text-field>
            </template>
          
        </v-data-table>
    
</template>

<script>
import { getAllOrgsAndUsers,updateUserCaseLoadCount } from "../dataservices/partnerDataService";
export default {
    name: 'Organization',
    data() {
        return {
           data :[],
           headers : [
            { text: 'Staff List', value: 'name' },
            { text: 'Email', value: 'Email' },
            { text: 'Caseload Count', value: 'caseload_count' },
            { text: 'Column 3', value: 'c3' },
            { text: 'Column 4', value: 'c4' },
           
        ]
        }
    },
    methods: {
        async updateCount(item){
            if(item.caseload_count !== null){
                await updateUserCaseLoadCount(item.Id,item.caseload_count);
            }
       
    }
    },
    async mounted() {
        this.data = await getAllOrgsAndUsers();
    },
    
};
</script>

<style scoped>
/* Your styles here */
</style>
import axios from 'axios';



const partnerGetAllUsers  = async () => {
   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/partnerApp/getAllUsers",{
    appId : 2,
   })
     return result.data;
}


const getAllOrgsAndUsers  = async () => {
  const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/partnerApp/getAllOrgsAndUsers",{
   appId : 2,
  })
    return result.data.listUsersResult;
}

const updateUserCaseLoadCount = async (userId,count) => {
  await axios.post("https://waproject-5dc59.uw.r.appspot.com/partnerApp/updateUserCaseLoadCount",{
    id : userId,
    caseload_count : count
  })
}

export {partnerGetAllUsers,getAllOrgsAndUsers,updateUserCaseLoadCount}
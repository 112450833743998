<template>
      <div>

        <v-treeview
    :items="items"
    hoverable
    activatable
  >
  <template v-slot:label="{ item }">
      <a @click="updateDisplayCase(item)">{{ item.name }}</a>
    </template>
</v-treeview>

      
      <ContractForm :key='key' v-if="formData !== null" :editContractObj='formData' :editMode='true' />
    </div>
</template>


<script>
import ContractForm from "../components/EntryForm.vue";
import { getCaseById} from "../dataservices/caseDataService";
export default {
  async mounted(){
    this.apiData = await getCaseById(this.$route.params.id);
      this.setParentItem();
      this.childs = this.apiData.child;
      this.items.push({
        id : this.$route.params.id,
        name : "Main Case Information: " + this.$route.params.id,
        children : this.childs.map(c => {
         return {
          id : c.caseData.CaseID,
          name :  `Copied Case Information: ${c.caseData.CaseID} | copied from Case: (${c.caseData.original_id})`,
          children : [],
         }
        })
      })
  },
  components: {
    ContractForm,
  },
  methods : {
    setParentItem(){
      this.formData = this.apiData.parent.caseData;
      this.formData.clientRace = this.apiData.parent.clientRace;
      this.formData.caseNumbers = this.apiData.parent.caseNumber.map(c => ({
        AssignmentType : c.AssignmentType,
        CaseNumberID : c.CaseNumberID,
      caseNumber :  c.CaseNumber,
      closedDate : c.closedDate,
        status : c.Status,
        incarcerationInfo : c.incarcerationInfo,
        expertUsed : c.expertUsed,
        expertTypes : this.apiData.parent.ExpertTypes.filter(e => e.CaseNumberID === c.CaseNumberID).map(ee => ee.ExpertTypeName),
        outcomes : this.apiData.parent.Outcomes.filter(e => e.CaseNumberID === c.CaseNumberID).map(ee => ee.OutcomesName),
        WeightValue : c.WeightValue
      }))
    },
    updateDisplayCase(item){
      if(item.id === this.$route.params.id){
        this.key = Math.random();
        return this.setParentItem();
      }
      this.key = Math.random();
      let child = this.childs.find(c => c.id === item.id);
      this.formData = child.caseData
      this.formData.userData = this.apiData.parent.caseData.userData;
      this.formData.clientRace = child.clientRace;
      this.formData.caseNumbers = child.caseNumber.map(c => ({
        AssignmentType : c.AssignmentType,
        CaseNumberID : c.CaseNumberID,
      caseNumber :  c.CaseNumber,
      closedDate : c.closedDate,
        status : c.Status,
        incarcerationInfo : c.incarcerationInfo,
        expertUsed : c.expertUsed,
        expertTypes : child.ExpertTypes.filter(e => e.CaseNumberID === c.CaseNumberID).map(ee => ee.ExpertTypeName),
        outcomes : child.Outcomes.filter(e => e.CaseNumberID === c.CaseNumberID).map(ee => ee.OutcomesName),
        WeightValue : c.WeightValue
      }))
      this.$forceUpdate();
    },
  },
  data() {
    return{
      key : 1,
      apiData : null,
      currentItem : null,
      formData : null,
      childs : [],
      items : [],
    }
  }
  
};
</script>
